<template>
  <b-modal
    id="delete-snippet"
    ref="delete-snippet"
    centered
    no-close-on-backdrop
    footer-class="flex-nowrap"
  >
    <template #modal-header>
      <h3>{{ $t('modals.deleteSnippet.title') }}</h3>
    </template>
    <div class="delete-icon text-center px-2">
      <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
      <div class="mt-2 mb-4" v-html="$t('modals.deleteSnippet.message')"></div>
    </div>
    <template #modal-footer="{ close, ok }">
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="deleteSnippet"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalDeleteSnippet',
  methods: {
    deleteSnippet() {
      this.$emit('delete-snippet');
      this.$refs['delete-snippet'].hide();
    },
  },
};
</script>

<style scoped lang="scss">
.delete-icon svg {
  font-size: 80px;
}
</style>
